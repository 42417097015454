import React from "react";
import { FaChevronRight } from "react-icons/fa/index.esm";

const CtaButton = ({
  label = "View",
  className = "",
  url,
  external = true,
}) => (
  <a
    href={url}
    className={`cta-button ${className}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    <span className="mr-1">{label} </span>
    <i>
      <FaChevronRight />
    </i>
  </a>
);

export default CtaButton;
