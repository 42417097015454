import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaMapMarkerAlt } from "react-icons/fa/index.esm";
import { graphql, useStaticQuery } from "gatsby";
import { mergePrismicPreviewData } from "gatsby-source-prismic";

// import Banner from "../components/Banner";
import { HTMLContent } from "components/Content";
import Img from "components/Image";
import Layout from "components/Layout";
import SEO from "components/Seo";
import CtaLink from "components/CtaLink";

const OnlineTrainingPage = () => {
  const data = useStaticQuery(graphql`
    query trainingPageQuery {
      slides: allFile(
        filter: { name: { glob: "*Slide*" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          childImageSharp {
            fluid(quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `);

  const slidePics = data.slides.nodes;

  const slideDescs = [
    "Click 'Student Login'",
    "Click the 'Enroll Now' link",
    "Fill out the form",
    "Once logged in with your new account, click on 'Catalog'",
    "Find the appropriate class",
    "Register for the class",
    "Payment form",
    "Now you will have the class listed on your 'dashboard' page, under the 'active' tab. Just click the arrow to start the class.",
    "After completing the course, click on the 'completed' tab to view a list of your completed classes.",
    "Click on the course to print your completion certificate",
  ];

  return (
    <Layout pageInfo={{ pageName: "inservice-renewals" }}>
      <SEO title="In-Service Renewals" />

      <section>
        <Container>
          <Row>
            <Col xs="12">
              <h2>In-Service Renewals</h2>

              <p
                className="font-weight-bold text-danger"
                style={{ fontSize: "22px" }}
              >
                Attention: This page is for in-service renewals and
                requalifications ONLY.
              </p>

              <p className="font-weight-bold">
                <b>Important:</b>
                <br />
                DCJS's system will only accept one in-service class per day per
                student!
              </p>

              <p className="py-4">
                <CtaLink
                  url="https://cloud.avilar.com/webmentor/campuses/ddsta/index.html"
                  label="Existing Users: Sign In Here"
                />
              </p>

              <p className="font-weight-bold mt-4 text-danger">
                New Users, please continue reading below for instructions.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="dark" id="dcjs">
        <Container>
          <Row>
            <Col xs="12">
              <h2>DCJS Registration Renewal</h2>

              <p>Renewal is a two-step process:</p>

              <ol>
                <li className="font-weight-bold py-3">
                  <p>
                    Take and complete the online training. Once the online
                    training has been completed, the training completion will be
                    automatically sent to DCJS.
                  </p>
                </li>

                <li className="font-weight-bold pb-3">
                  <p>
                    Complete the Registration Renewal Application with DCJS:
                  </p>

                  <p className="pb-3">
                    <CtaLink
                      url="https://www.cms.dcjs.virginia.gov/GLSuiteWeb/Clients/VADCJS/Private/Shared/login.aspx"
                      label="DCJS Online Regulatory Licensing System"
                    />
                  </p>

                  <p>
                    Once the training, profile, application, and picture has
                    been completed DCJS will mail your registration.
                  </p>
                </li>
              </ol>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bluedark" id="instructions">
        <Container>
          <Row>
            <Col xs="12" md="9">
              <h2>Online Classroom Sign-Up Instructions</h2>

              <p className="font-weight-bold pb-3">
                Read all of the instructions below. Then, create your online
                classroom account by clicking the blue "Create Online Student
                Account" button shown after the last step.
              </p>

              <ol>
                {slidePics.map((slide, i) => (
                  <li
                    key={i}
                    style={{ paddingBottom: "40px", marginTop: "40px" }}
                  >
                    <p>{slideDescs[i]}</p>
                    <Img
                      fluid={slide.childImageSharp.fluid}
                      alt={`Instructions screenshot ${i}`}
                      className="thumbnail-img-wrap"
                      style={{ margin: "0" }}
                      src={slide.url}
                    />
                  </li>
                ))}

                <li>
                  <p className="font-weight-bold">
                    Once you have completed the course, it will automatically
                    Transmit at 2350 hours the day it is completed.
                  </p>
                </li>
              </ol>

              <p className="pt-4">
                <CtaLink
                  url="https://cloud.avilar.com/webmentor/campuses/ddsta/index.html"
                  label="Create Online Student Account"
                />
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};
export default OnlineTrainingPage;
